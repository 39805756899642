<template>
  <div class="compare-modal  modal fade" id="compareModal" tabindex="-1" aria-labelledby="compareModalLabel"
       aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content themeBlue-before">
        <div class="modal-body p-0">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          <div class="remodal-wrapper remodal-is-opened" style="display: block;">
            <div class="remodal c-remodal-compare remodal-is-initialized remodal-is-opened"
                 data-remodal-id="add-compare-product" role="dialog" aria-labelledby="modal1Title"
                 aria-describedby="modal1Desc" tabindex="-1">
              <div class="c-remodal-compare__header">
                <form @submit.prevent="handleInput" class="c-form-compare-search">
                  <div class="c-form-compare-search__row">
                    <div class="c-form-compare-search__col">
                      جستجو
                    </div>
                    <div class="c-form-compare-search__col c-form-compare-search__col--field"><label
                        class="c-ui-input c-ui-input--search">
                      <SearchSvg class="search-icon" :width="25" />

                      <input class="c-ui-input__field js-product-title" type="text" ref="search" @keyup="handleInput" v-model="q"
                                                                     placeholder="کالای مورد نظر خود را جستجو کنید..."></label>
                      <div class="h-100">
                        <select v-model="brandId" class="h-100" name="brand">
                          <option value="" selected>همه ی برند ها</option>
                          <option v-for="brand in brands" :value="brand.id" :key="brand.id">{{ brand.name }}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div class="c-remodal-compare__content">
                <div class="c-form-compare-selector">
                  <div class="c-form-compare-selector__row">
                    <div class="c-form-compare-selector__col">
                      <div class="js-compare-product-template u-hidden"><label
                          class="c-form-compare-selector__item js-compare-selector">
                        <figure class="img"><img data-src="" class="js-compare-image" alt="" src="" loading="lazy"></figure>
                        <span class="title js-compare-title"></span></label></div>
                      <div v-if="sendingRequest" style="color: #0e3a62" class="spinner-border mb-3">
                        <span class="sr-only">Loading...</span>
                      </div>
                      <div v-else-if="products.length === 0">
                        <p>
                          موردی یافت نشد
                        </p>
                      </div>
                      <ul class="c-form-compare-selector__items js-compare-container">
                        <li v-for="product in products" :key="product.id" @click="select(product)">
                          <label class="c-form-compare-selector__item js-compare-selector" data-id="4122136">
                          <figure class="img">
                            <img data-src="" class="js-compare-image"
                                                   :alt="product.title" :src="product.image"
                                                   loading="lazy"></figure>
                          <span class="title js-compare-title">{{ product.title }}</span></label>
                        </li>
                        <li></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import SearchSvg from "../svgs/SearchSvg";
export default {
  name: "AddToCompare",
  components: {SearchSvg},
  data() {
    return {
      brandId: '',
      categoryId: null,
      products: null,
      q: '',
      searchFunction: null,
      sendingRequest: false,
    }
  },
  props: {
    compares: Array
  },
  watch: {
    brandId() {
      this.handleInput({type: 'submit'})
    }
  },
  computed: {
    brands() {
      return this.$store.state.header ? this.$store.state.header.brands : []
    }
  },
  created() {
    this.searchFunction = debounce(this.sendRequest ,400);
    this.$root.$off('openCompare')
    this.$root.$on('openCompare', (payload) => {
      this.categoryId = payload.categoryId
      this.products = null;
      this.searchFunction()
      this.$('#compareModal').modal('show')
    });
  },
  methods: {
    handleInput(e) {
      if (this.q !== this.oldQ && this.q !== '' || e.type == 'submit') {
        this.oldQ = this.q;
        this.searchFunction();
      }
    },
    sendRequest() {
      let q = this.q;
      let c = this.categoryId;
      this.sendingRequest = true

      this.$axios.get('/api/search', {
        params: {
          title: q,
          c: c,
          brand_id: this.brandId
        }
      })
          .then((response) => {
            this.products = response.data.data;
          })
          .catch(function (error) {
            console.error(error);
          }).finally(() => {
        this.sendingRequest = false;
      });
    },
    select(product) {
      if (this.compares.includes(product.id)) {
        this.$('#compareModal').modal('hide')
        return
      }
      this.$('#compareModal').modal('hide')
      this.$router.push(this.$route.fullPath + ',' + product.id)
    }

  }
}
</script>

<style scoped>
.remodal, [data-remodal-id] {
  display: none;
}

.remodal {
  position: relative;
  outline: none;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
}

.remodal-is-initialized {
  display: inline-block;
}

.remodal {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  color: #2b2e38;
  background: #fff;
}

.remodal {
  vertical-align: middle;
}

.remodal-close {
  position: absolute;
  top: 15px;
  left: 15px;
  display: block;
  overflow: visible;
  margin: 0;
  cursor: pointer;
  -webkit-transition: color .2s;
  transition: color .2s;
  text-decoration: none;
  color: #989898;
  border: 0;
  outline: 0;
  background: transparent;
  font-size: 23px;
  line-height: 1;
  padding: 5px;
  z-index: 100;
}

.remodal-close:before {
  content: "\E119\00FE0E";
  vertical-align: top;
}

.remodal-close:focus, .remodal-close:hover {
  color: #009ec9;
}

.c-ui-input {
  position: relative;
  display: inline-block;
  width: 100%;
  line-height: 22px;
}

.c-ui-input__field {
  border-radius: 8px;
  background: #fff;
  border: 1px solid #c8c8c8;
  color: #717171;
  font-size: 14px;
  font-size: 1rem;
  line-height: 1.571;
  padding: 11px 12px;
  width: 100%;
  letter-spacing: -.8px;
}

.c-ui-input--search .c-ui-input__field {
  padding-right: 42px;
}

.c-form-compare-search__row {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -9px;
  margin-left: -9px;
}

.c-form-compare-search__col {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 9px;
  padding-left: 9px;
  -ms-flex: 0 0 auto;
  -webkit-box-flex: 0;
  flex: 0 0 auto;
  width: auto;
  max-width: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.c-form-compare-search__col--field {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  -webkit-box-flex: 1;
  flex-grow: 1;
  max-width: 100%;
}

.c-form-compare-search .selectric {
  border-radius: 8px 0 0 8px;
}

.c-form-compare-search .selectric-c-form-compare-search__brands {
  max-width: 200px;
}

.c-form-compare-search .c-ui-input__field {
  border-radius: 0 5px 5px 0;
  border-left: none;
}

.c-form-compare-selector__row {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: 0;
  margin-left: 0;
}

.c-form-compare-selector__col {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding: 17px 17px 0;
}

.c-form-compare-selector__items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  list-style: none;
  margin: -17px 0 0;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  max-height: 60vh;
  overflow: auto;
  padding: 0 0 17px;
  min-height: 290px;
}

.c-form-compare-selector__items li {
  position: relative;
  margin-top: 17px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 32%;
  flex: 0 0 32%;
  max-width: 32%;
}

.c-form-compare-selector__item {
  width: 100%;
  height: 185px;
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #e9e9e9;
  text-align: center;
  display: block;
  cursor: pointer;
}

.c-form-compare-selector__item .img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 94px;
  margin-bottom: 15px;
  position: relative;
}

.c-form-compare-selector__item .img img {
  overflow: hidden;
}

.c-form-compare-selector__item .img img {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  -o-object-fit: scale-down;
  object-fit: scale-down;
}

.c-form-compare-selector__item .title {
  font-size: 12px;
  font-size: .857rem;
  line-height: 2;
  display: block;
  padding: 0 18px;
  max-height: 60px;
  overflow: hidden;
}

.remodal-wrapper .remodal {
  max-height: 95vh;
  overflow: auto;
}

.remodal {
  line-height: 22px;
  max-width: 820px;
}

.c-remodal-compare {
  text-align: right;
  max-width: 900px;
  background-color: #f8f8f8;
}

.c-remodal-compare__header {
  background: #ececec;
  border-bottom: 1px solid #dedede;
  padding: 19px 37px 20px 63px;
}

.c-remodal-compare .remodal-close {
  top: 10px;
  left: 10px;
  font-size: 14px;
}

.remodal {
  display: none;
}

.remodal-wrapper .remodal {
  display: inline-block;
}

.u-hidden {
  display: none !important;
}

@media screen and (min-width: 1366px) {
  .c-form-compare-selector__item {
    width: 221px;
    height: 215px;
    padding-top: 20px;
  }

  .c-form-compare-selector__item .img {
    height: 115px;
  }

  .c-remodal-compare {
    max-width: 980px;
  }
}

@media screen and (min-width: 576px) {
  .modal-dialog {
    max-width: 900px;
    margin: 1.75rem auto;
  }
}

.search-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

label {
  margin-bottom: 0;
}

.c-form-compare-search select {
  border: 1px solid #c4c4c4;
  border-radius: 5px 0 0 5px;
  min-width: 140px;
  padding-right: 9px;
}

*::placeholder {
  color: #717171;
}
select{
  color: #717171;
}

.close {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 2;
}
</style>
