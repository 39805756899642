<template>
  <main class="mb-5">
    <AddToCompare :compares="compares" />
    <section class="pages-path d-flex my-3 py-2 px-3 container bg-white box-shadow20 border-radius15 ">
      <svg class="ml-2" width="17" height="17" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.15722 20.7714V17.7047C9.1572 16.9246 9.79312 16.2908 10.581 16.2856H13.4671C14.2587 16.2856 14.9005 16.9209 14.9005 17.7047V17.7047V20.7809C14.9003 21.4432 15.4343 21.9845 16.103 22H18.0271C19.9451 22 21.5 20.4607 21.5 18.5618V18.5618V9.83784C21.4898 9.09083 21.1355 8.38935 20.538 7.93303L13.9577 2.6853C12.8049 1.77157 11.1662 1.77157 10.0134 2.6853L3.46203 7.94256C2.86226 8.39702 2.50739 9.09967 2.5 9.84736V18.5618C2.5 20.4607 4.05488 22 5.97291 22H7.89696C8.58235 22 9.13797 21.4499 9.13797 20.7714V20.7714" stroke="#999" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="ml-2 mt-1 bi bi-chevron-double-left" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M8.354 1.646a.5.5 0 0 1 0 .708L2.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" fill="#a5a5a5"/>
        <path fill-rule="evenodd" d="M12.354 1.646a.5.5 0 0 1 0 .708L6.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" fill="#a5a5a5"/>
      </svg>
      <router-link tag="span" to="/" class="fontsize12 text-color-666">خانه</router-link>
      <svg class="mx-2 mt-1" width="12" height="12" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.5 19L8.5 12L15.5 5" stroke="#a5a5a5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      <router-link tag="span" to="/compare" class="fontsize11 text-color-a5">مقایسه</router-link>
    </section>
    <hr class="w-100 container d-md-block d-none bg-color-fa"/>
    <div class="container text-center">
      <div class="box-shadow20 border-radius15 p-1 p-sm-4">
        <span v-if="products && !products.length">
          <p class="text-info text-center">محصولی جهت مقایسه وجود ندارد</p>
        </span>
        <div v-else-if="products" class="mb-sm-3 mb-1 row">
          <a @click="(e) => goTo(e,{name:'product.detail' , params:{id: product.id , slug: product.slug}})"
                       :href="$router.resolve({name:'product.detail' , params:{id: product.id , slug: product.slug}}).href"
                       v-for="product in products" :key="product.id" class="compare-item" :class="'col-' + col">
            <img @click="remove(product)" class="close" :src="require('../../assets/images/close.png')" alt="Remove">
            <img :src="product.image" :alt="product.title">
            <h6 class="mt-2">
              {{ product.title }}
            </h6>
            <button class="btn d-none d-sm-block">مشاهده و خرید</button>
          </a>
          <div @click="addCompare" class="add-compare" :class="'col-' + col" v-if="products.length < (col == 4 ? 3 : 4)">
            <img :width="col == 4 ? 100 : 200" :src="require('../../assets/images/add.png')" alt="افزودن کالای جدید">
            <div>برای افزودن کالا کلیک کنید</div>
          </div>
        </div>
        <b-spinner v-else type="grow" label="Spinning"></b-spinner>
        <div v-if="table" class="compare-table">
          <div class="attribute" v-for="item in table" :key="item.attribute.id">
            <div class="attribute-title">
              <span>{{ item.attribute.name }}</span>
            </div>
            <div class="row">
              <div v-for="(value,index) in item.values" :key="index" class="value" :class="'col-' + col">
                <span>{{ value }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </main>
</template>

<script>
import AddToCompare from "./component/compare/AddToCompare";
export default {
  name: "ComparePage",
  components: {AddToCompare},
  data() {
    return {
      products: null,
      compares: []
    }
  },
  inject: ['mediaQueries'],
  computed: {
    col() {
      return this.mediaQueries.mobileSize ? '4' : '3'
    },
    table() {
      if (!this.products || !this.products.length) {
        return null
      }
      let table = [];
      if (this.hasAnyBrand()) {
        let brands = Array(this.products.length)
        this.products.forEach((product, index) => {
          brands[index] = product.brand.name;
        })
        table.push({
          attribute: {
            id: 'brand', name: 'برند'
          },
          values: brands
        })
      }
      this.extractAttributes().forEach(attr => {
        let values = Array(this.products.length)
        this.products.forEach((product, index) => {
          values[index] = this.getAttributeValueOf(attr.id, product)
        })
        table.push({
          attribute: attr,
          values
        })
      })

      return table
    },
  },
  mounted() {
    this.$store.commit('resetCompare')
    let compares = this.$route.query.compares;
    this.compares = compares
    if (!compares) {
      this.products = []
      return
    }
    this.$axios.get('api/products/compare', {
      params: {
        ids: compares.split(',')
      }
    }).then(res => {
      this.products = res.data.data.map(i => {i.attributes = i.attributes_active; return i})
      console.log(this.table)
    }).catch(error => {
      this.$root.error_notification(error)
    })
  },
  methods: {
    addCompare() {
      this.$root.$emit('openCompare', {
        categoryId: this.products[0].category.parent ? this.products[0].category.parent.id : this.products[0].category.id
      })
    },
    remove(product) {
      let id = product.id;
      let path = this.$route.fullPath
      let final = ''
      if (path.includes(',' + id)) {
        final = path.replace(',' + id, '')
      } else {
        final = path.replace(id + ',', '')
      }
      this.$router.push(final)
    },
    getSortedAttribute(attributes) {
      return attributes.sort((a,b) => {
        return a.id < b.id
      })
    },
    extractAttributes() {
      let countedAttributes = []
      let attributes = []
      this.products.forEach(product => {
        product.attributes.forEach(attr => {
          if (!countedAttributes.includes(attr.id)) {
            countedAttributes.push(attr.id)
            attributes.push(attr)
          }
        })
      })

      return this.getSortedAttribute(attributes)
    },
    hasAnyBrand() {
      let yes = false;
      this.products.forEach(p => {
        if (p.brand) {
          yes = true
        }
      })
      return yes;
    },
    getAttributeValueOf(attrId, product) {
      let val = null;
      product.attributes.every(attr => {
        if (attr.id == attrId) {
          val = attr.pivot.value;
          return false
        }
        return true
      })
      return val;
    },
    goTo(e, url) {
      e.preventDefault()
      if (e.target.classList.contains('close')) {
        return
      }
      this.$router.push(url)
    }
  }
}
</script>

<style scoped>
.attribute-title {
  display: block;
  text-align: right;
  background: #eaeaea;
  padding: 8px 15px;
  border-radius: 5px;
  font-weight: bold;
}

.value {
  padding: 9px 14px;
}

.btn {
  border-radius: 8px;
  background-color: var(--color-themeBlue);
  border: 1px solid var(--color-themeBlue-l);
  padding: 6px 31px;
  color: #fff;
  transition: 200ms all;
  margin: auto;
}
.btn:hover {
  box-shadow: 0 0 10px 8px rgba(0, 0, 0, 0.05);
}

@media screen and (max-width: 768px){
  .compare-item {
    padding-right: 8px;
    padding-left: 8px;
  }
}

.add-compare {
  border-radius: 19px;
  border: 5px dashed #d2d2d2;
  background: none;
  max-width: 270px;
  margin-top: auto;
  margin-bottom: auto;
  display: block;
  padding: 35px;
  font-size: 15px;
  font-size: 1.071rem;
  line-height: 1.467;
  color: #b3b3b3;
  cursor: pointer;
}

.close {
  position: absolute;
  right: 5px;
  top: 5px;
  width: 35px;
}
</style>
